<template>  
  <div class="level">
    <div class="level-item">
        <div class="card" v-if="! postSuccess">
        <header class="card-header has-background-primary">
            <h4 class="card-header-title is-centered title is-4 has-text-white">Signup for VEECLi Service</h4>
        </header>
        <div class="card-content">  
            <form ref="form" @submit.prevent="handleSubmit">
                <b-field label="Email"
                    message="Please enter a valid email address">
                    <b-input type="email"
                        icon-pack="fas"
                        icon="envelope"
                        v-model="email"
                        maxlength="100"                   
                        >
                    </b-input>
                </b-field>

                <error-message v-bind:messagetype='messagetype' v-bind:errors='errors'/>
                <button type="submit" class="button is-primary" :disabled='!formComplete'>Send verification Email</button>
            </form>
        </div>
    </div>

    <!-- <section class="hero is-gray-light" v-else>
        <div class="hero-head">
            <div class="container">
            <h1 class="title">
                Validation Email Sent!
            </h1>
        </div>
    <div class="hero-body">
        <h2 class="subtitle">
            Thank you for signing up.  We have sent a validation email with a link.  Please check your email and click on the link to complete the registration.
        </h2>
        </div>
    </div>
    </section> -->
    <div class="has-text-centered is-gray" v-else>
        <div class="title">Validation Email Sent!</div>
        <p class="">
            Thank you for signing up.  We have sent a validation email with a link.  Please check your email and click on the link to complete the registration.<br>
            If you don't find the email, do not forget to check the spam folder.  If you still don't receive it, please email support@veecli,com
        </p>    
    </div>

    </div>    
   </div>
</template>

<script>

import axios from 'axios'
axios.defaults.headers.common['Access-Control-Allow-Origin'] = '*';

import ErrorMessage from '../../components/app/ErrorMessage'

export default {
    
    data() {
        return {
            email: '',
            errors: [],
            postSuccess: false,
            messagetype: 'is-warning',
            authKey: 'Basic ' + process.env.VUE_APP_API_KEY,
        }
    },

    components: {
        ErrorMessage
    },

    methods: {

        handleSubmit() {

            if ( this.formComplete ) {

                var url = process.env.VUE_APP_API_ROOT_URL + "/users/signup"

                axios.post(url,
                    {
                        email: this.email
              
                    })

                .then( (response) => {

                    this.email = ""

                    if (response.status === 200) {
                        this.postSuccess = true
                        this.$buefy.notification.open({
                                message: 'Thank you for signing up.  A validation email has been sent.  Please click on the link in the email to complete signup.',
                                type: 'is-success',
                                duration: 6000,
                                'has-icon': true,
                                'icon-pack': 'fas',
                                icon: 'exclamation-circle'
                            })
                    }
                })
                    
                .catch( (err) => {
                    if (process.env.VUE_APP_DEBUG == 'true') console.log(err.response.status, err.response.data)
                    this.email=""
                    this.$buefy.notification.open({
                            message: err.response.data + ' Registration Failed. Please try again!',
                            type: 'is-danger',
                            duration: 6000,
                            'has-icon': true,
                            'icon-pack': 'fas',
                            icon: 'exclamation-circle'
                        })
                })  
            }

        },

        isEmailValid(email) {
            return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email)
        },

    },

    computed: {

        formComplete() {
            if (this.isEmailValid(this.email)) {   
                return true
            } else {
                return false
            }         

        },

    }

}
</script>

<style scoped>

.level {
    padding-top: 100px;
}

</style>